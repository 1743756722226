* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

html ::-webkit-scrollbar {
    width: 12px;
}

html ::-webkit-scrollbar-thumb {
    background-color: #c3c7c9;
    border-radius: 12px;
    border: 3px solid transparent;
    background-clip: content-box;
}

html ::-webkit-scrollbar-track {
    background-color: transparent;
}

@property --animate-duration {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}
@property --Color {
  syntax: '<color>';
  initial-value: #4afd71;
  inherits: false;
}

@keyframes rotate {
  0% {
    --animate-duration: 0deg;
    --Color: #4afd71;
  }
  100% {
    --animate-duration: 360deg;
    --Color: #4afd71;
  }
}
